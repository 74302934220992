export const config = {
  defaul: { url: "https://www.facebook.com/BDSLviv" },
  myBdsl: { url: "https://my.bdslviv.org.ua/invites/" },
  "bdsl-bd-party": {
    url: "https://events.bdslviv.org.ua/bdsl-bd-party/",
    backgroundImage:
      "https://firebasestorage.googleapis.com/v0/b/bdsl-events.appspot.com/o/events%2Fposters%2Fbdsl-bd-poster.jpg?alt=media&token=446f06c3-3f09-416e-aed9-365a121b28f6",
    titleUa: 'Твій квиток на BDSLviv Birthday Party "Ангели та Демнони"',
    titleEn: 'Your ticket onto BDSLviv Birthday Party "Angels & Devils"',
    theme: "dark",
  },
};
