import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useParams } from "react-router-dom";
import Helmet from "react-helmet";
import "./App.css";
import { config } from "./config";
import QRCodeGenerator from "./services/qr-code-generator";

function App() {
  const { token, event = "myBdsl" } = useParams();
  let logoFromFile = require("./logo192.png");

  let image = config[event]?.backgroundImage;

  const qrCode = (
    <QRCodeGenerator
      value={
        token === "welcome" || !config[event]
          ? config.default?.url
          : config[event]?.url + token
      }
      ecl="H"
      size={300}
      color="#700070"
      backgroundColor="#F2F2F2"
      quietZone={10}
      logo={logoFromFile}
      logoSize={100}
      logoMargin={10}
      logoBackgroundColor="#F2F2F2"
      logoBorderRadius={15}
    />
  );

  const svgString = encodeURIComponent(renderToStaticMarkup(qrCode));

  const dataUri = `data:image/svg+xml,${svgString}`;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
      }}
    >
      {image && (
        <div
          className="background"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      )}
      <h2 className={`title theme-${config[event]?.theme || "light"}`}>
        {config[event]?.titleUa}
      </h2>
      <Helmet meta={[
        { property: "og:image", content: dataUri },
        { property: "og:title", content: "Your ticket to BDSLviv" },
      ]} />
      {qrCode}
      <h2 className={`title theme-${config[event]?.theme || "light"}`}>
        {config[event]?.titleEn}
      </h2>
    </div>
  );
}

export default App;
