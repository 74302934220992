import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import App from "./App";

export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/:event/:token">
          <App />
        </Route>
        <Route path="/:token">
          <App />
        </Route>
        <Redirect to="/welcome" />
      </Switch>
    </Router>
  );
};
