import React, { useMemo } from "react";
import genMatrix from "./genMatrix";
import transformMatrixIntoPath from "./transformMatrixIntoPath";

const renderLogo = ({
  size,
  logo,
  logoSize,
  logoBackgroundColor,
  logoMargin,
  logoBorderRadius,
}) => {
  const logoPosition = (size - logoSize - logoMargin * 2) / 2;
  const logoBackgroundSize = logoSize + logoMargin * 2;
  const logoBackgroundBorderRadius =
    logoBorderRadius + (logoMargin / logoSize) * logoBorderRadius;

  return (
    <g x={logoPosition} y={logoPosition} transform={`translate(${logoPosition}, ${logoPosition})`}>
      <defs>
        <clipPath id="clip-logo-background">
          <rect
            width={logoBackgroundSize}
            height={logoBackgroundSize}
            rx={logoBackgroundBorderRadius}
            ry={logoBackgroundBorderRadius}
          />
        </clipPath>
        <clipPath id="clip-logo">
          <rect
            width={logoSize}
            height={logoSize}
            rx={logoBorderRadius}
            ry={logoBorderRadius}
          />
        </clipPath>
      </defs>
      <g>
        <rect
          width={logoBackgroundSize}
          height={logoBackgroundSize}
          fill={logoBackgroundColor}
          clipPath="url(#clip-logo-background)"
        />
      </g>
      <g x={logoMargin} y={logoMargin} transform={`translate(${logoMargin}, ${logoMargin})`}>
        <image
          className="App-logo"
          width={logoSize}
          height={logoSize}
          preserveAspectRatio="xMidYMid slice"
          href={logo}
          clipPath="url(#clip-logo)"
        />
      </g>
    </g>
  );
};

const QRCodeGenerator = ({
  value = "this is a QR code",
  size = 100,
  color = "black",
  backgroundColor = "white",
  logo,
  logoSize = size * 0.2,
  logoBackgroundColor = "transparent",
  logoMargin = 2,
  logoBorderRadius = 0,
  quietZone = 0,
  enableLinearGradient = false,
  gradientDirection = ["0%", "0%", "100%", "100%"],
  linearGradient = ["rgb(255,0,0)", "rgb(0,255,255)"],
  ecl = "M",
  getRef,
  onError,
}) => {
  const result = useMemo(() => {
    try {
      return transformMatrixIntoPath(genMatrix(value, ecl), size);
    } catch (error) {
      if (onError && typeof onError === "function") {
        onError(error);
      } else {
        // Pass the error when no handler presented
        throw error;
      }
    }
  }, [value, size, ecl, onError]);

  if (!result) {
    return null;
  }

  const { path, cellSize } = result;

  return (
      <svg
        ref={getRef}
        viewBox={[
          -quietZone,
          -quietZone,
          size + quietZone * 2,
          size + quietZone * 2,
        ].join(" ")}
        width={size}
        height={size}
      >
        <defs>
          <linearGradient
            id="grad"
            x1={gradientDirection[0]}
            y1={gradientDirection[1]}
            x2={gradientDirection[2]}
            y2={gradientDirection[3]}
          >
            <stop offset="0" stopColor={linearGradient[0]} stopOpacity="1" />
            <stop offset="1" stopColor={linearGradient[1]} stopOpacity="1" />
          </linearGradient>
        </defs>
        <g>
          <rect
            x={-quietZone}
            y={-quietZone}
            width={size + quietZone * 2}
            height={size + quietZone * 2}
            fill={backgroundColor}
          />
        </g>
        <g>
          <path
            d={path}
            strokeLinecap={"square"}
            stroke={enableLinearGradient ? "url(#grad)" : color}
            strokeWidth={cellSize}
          />
        </g>
        {logo &&
          renderLogo({
            size,
            logo,
            logoSize,
            logoBackgroundColor,
            logoMargin,
            logoBorderRadius,
          })}
      </svg>
  );
};

export default QRCodeGenerator;
